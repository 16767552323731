import { PageQuery } from "@/api/inc/Entity";
import Request from "@/api/inc/Request";

export default class ApiSalesWorkOrder {
  static list(query?: SalesWorkOrderQuery): Promise<SalesWorkOrder[]> {
    return Request.get('/ding/saleOrder/list', {
      params: query
    })
  }

  static create(form: SalesWorkOrderFormBody): Promise<void> {
    return Request.post('/ding/saleOrder/save', form)
  }

  static edit(id: number, form: SalesWorkOrderFormBody): Promise<void> {
    return Request.post('/ding/saleOrder/update', {
      id,
      ...form
    })
  }

  static cancel(id: number): Promise<void> {
    return Request.get('/ding/saleOrder/cancel', {
      params: {
        id
      }
    })
  }

  static statistics(): Promise<SalesWorkOrderStatistic[]> {
    return Request.get('/ding/saleOrder/statistics')
  }

  static createForm(): SalesWorkOrderFormBody {
    return {
      customer_name: '',
      customer_address: '',
      sign_man: '',
      sign_phone: '',
      sum_amount: 0,
      duration: 0,
      month_money: 0,
      year_money: 0,
      compensation_amount: 0,
      deposit: 0,
      videohost_count: 0,
      alarmhost_count: 0,
      nvr_chana_channel_count: 0,
      remark: '',
    }
  }
}

export interface SalesWorkOrderQuery extends PageQuery {
  status?: number
}

export enum SalesWorkOrderStatus {
  CREATED = 0,
  CANCELED_BY_USER = 1,
  CANCELED_BY_CENTER = 2,
  RECORDED = 3
}

export namespace SalesWorkOrderStatus {
  export function infoOf(status: SalesWorkOrderStatus) {
    switch (status) {
      case SalesWorkOrderStatus.CREATED:
        return {
          name: '已创建',
          color: 'default'
        }
      case SalesWorkOrderStatus.CANCELED_BY_USER:
        return {
          name: '销售方取消',
          color: 'error'
        }
      case SalesWorkOrderStatus.CANCELED_BY_CENTER:
        return {
          name: '管理方取消',
          color: 'error'
        }
      case SalesWorkOrderStatus.RECORDED:
        return {
          name: '已录入',
          color: 'success'
        }
      default:
        return {
          name: '未知',
          color: 'default'
        }
    }
  }
}

export interface SalesWorkOrder {
  id: number
  customer_name: string
  customer_address: string
  sign_man: string
  sign_phone: string
  sum_amount: number
  duration: number
  month_money: number
  year_money: number
  compensation_amount: number
  deposit: number
  videohost_count: number
  alarmhost_count: number
  nvr_chana_channel_count: number
  remark: string
  status: SalesWorkOrderStatus
}

export type SalesWorkOrderFormBody = Omit<SalesWorkOrder, 'id' | 'status'>

export interface SalesWorkOrderStatistic {
  status: SalesWorkOrderStatus,
  count: number
}
