
import ApiSalesWorkOrder, {
  SalesWorkOrder,
  SalesWorkOrderStatistic,
  SalesWorkOrderStatus
} from "@/api/ApiSalesWorkOrder"
import { Options, Vue } from "vue-class-component"
import dd from "dingtalk-jsapi"
import { PageQuery } from "@/api/inc/Entity"
import {
  PayCircleOutlined,
  ClockCircleOutlined,
  CaretDownOutlined,
  PlusOutlined,
  PhoneOutlined,
  EnvironmentOutlined
} from "@ant-design/icons-vue"

@Options({
  components: {
    PayCircleOutlined,
    ClockCircleOutlined,
    CaretDownOutlined,
    PlusOutlined,
    PhoneOutlined,
    EnvironmentOutlined
  }
})
export default class SalesWorkOrderList extends Vue {
  isLoading = false
  list: Array<SalesWorkOrder> = []
  pageQuery: Record<keyof PageQuery, number> = {
    page: 1,
    size: 10
  }
  hasMore = true
  filterStatus: SalesWorkOrderStatus | null = null
  statics: SalesWorkOrderStatistic[] = []

  mounted() {
    this.reload()
  }

  statusInfo(status: SalesWorkOrderStatus) {
    return SalesWorkOrderStatus.infoOf(status)
  }

  get filterStatusText() {
    return filterStatusTextOf(this.filterStatus)
  }

  loadNextPage() {
    this.isLoading = true
    this.pageQuery.page++
    return ApiSalesWorkOrder.list({
      ...this.pageQuery,
      status: this.filterStatus ?? undefined
    })
      .then(res => {
        if (res.length < this.pageQuery.size) {
          this.hasMore = false
        }
        this.list.push(...res)
      })
      .catch(() => {
        this.pageQuery.page--
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  reload() {
    this.pageQuery.page = 0
    this.list = []
    this.loadNextPage()
    ApiSalesWorkOrder.statistics()
      .then(res => {
        this.statics = res
      })
  }

  open(item?: SalesWorkOrder) {
    this.$router.push({
      name: "SalesWorkOrderForm",
      params: {
        defaultSource: item && JSON.stringify(item)
      }
    })
  }

  openFilter() {
    const options = [
      null, SalesWorkOrderStatus.CREATED,
      SalesWorkOrderStatus.CANCELED_BY_USER,
      SalesWorkOrderStatus.CANCELED_BY_CENTER,
      SalesWorkOrderStatus.RECORDED
    ]
    dd.device.notification.actionSheet({
      title: '请选择筛选状态',
      cancelButton: '取消',
      otherButtons: options.map(item => filterStatusTextOf(item)),
    })
      .then(res => {
        if (res.buttonIndex < 0) return

        this.filterStatus = options[res.buttonIndex]
        this.reload()
      })
  }

  cancel(item: SalesWorkOrder) {
    dd.device.notification.confirm({
      title: "警告",
      message: "确定取消这个工单吗？",
      buttonLabels: ["取消", "确定"]
    })
      .then(({ buttonIndex }) => {
        if (buttonIndex === 1) {
          dd.device.notification.showPreloader({
            text: "处理中..."
          })
          ApiSalesWorkOrder.cancel(item.id)
            .then(() => {
              this.reload()
            })
            .finally(() => {
              dd.device.notification.hidePreloader({})
            })
        }
      })
  }
}

function filterStatusTextOf(status: SalesWorkOrderStatus | null) {
  if (status !== null) {
    return SalesWorkOrderStatus.infoOf(status).name
  } else {
    return "全部"
  }
}
